import OneTemplate, {
  OneTemplateContent,
  OneTemplateSummary
} from '@loggi/components/src/one/template';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Checkbox,
  Divider,
  useTheme,
  useMediaQuery,
  Typography,
  makeStyles
} from '@material-ui/core';
import Toast from '@loggi/components/src/one/toast';
import { ReactComponent as FilterIcon } from '@loggi/components/src/one/shared/images/package-filter.svg';
import { useHierarchyChildren } from '@loggi/components/src/one/hooks';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import useFilter from '@loggi/components/src/one/use-filter/useFilter';

import NotificationDialog from './components/package-dialog-base';
import PackagesSummary from './components/packages-summary';
import PackagesListWrapper from './components/packages-list-wrapper';
import PackagesFilters from './components/packages-filters';
import BulkCancellationResultsSummary from './components/packages-bulk-cancellation-results/bulk-cancellation-results.summary.component';
import BulkCancellationResults from './components/packages-bulk-cancellation-results/bulk-cancellation-results.component';
import LoadingDialog from './loading-dialog.component';

import usePackages from './usePackages';
import { CLEAR_PAGINATION } from './constants';
import PackagesBulkCancellationButton from './components/packages-bulk-cancellation-button';
import PackagesBulkDownloadLabelsButton from './components/packages-bulk-download-labels-button';
import PackagesReportButton from './components/packages-download-report/packages-report-button';
import PackagesListTabs from './components/packages-list-tabs/packages-list-tabs.component';
import PackagesSearchBar from './components/packages-search/index';
import packagesApi from '../packages-api';
import { useRemoteConfig, useFeature } from '../../firebase';

/**
 * This is the packages screen root, it's responsible for getting the data
 * from API using the usePackages hook, and then passing to its children components
 * It receives no params, it renders itself without external dependencies
 * @componentType container
 * @returns {*}
 */
const useStyles = makeStyles(({ palette }) => ({
  boxOptions: {
    backgroundColor: palette.grey[100]
  }
}));

const PackagesList = () => {
  const classes = useStyles();
  const [packagesData, itemsCount, isLoading, error] = usePackages({});
  const [checked, setChecked] = useState([]);
  const [trackingAndCompanyPair, setTrackingAndCompanyPair] = useState([]);
  const [bulkCancel, setBulkCancel] = useState(false);
  const [bulkCancelData, setbulkCancelData] = useState({});
  const [bulkActionWarningDialog, setBulkActionWarningDialog] = useState(false);
  const [hasMixedCompanyIds, setHasMixedCompanyIds] = useState(false);
  const setFilter = useFilter();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const showSelectAllCheckBox =
    !error && !isLoading && !isDesktop && packagesData?.length > 0;
  const hasBulkCancelData = Object.keys(bulkCancelData).length !== 0;

  const disableCompanyHierarchy = useFeature('disable_company_hierarchy');
  const { value: showCompanyCnpj } = useRemoteConfig(
    'enable_company_cnpj_in_package_list'
  );

  const hierarchyChildrenList = useHierarchyChildren() ?? [];
  const useHierarchySpecifcs =
    showCompanyCnpj === 'true' &&
    hierarchyChildrenList.length !== 0 &&
    !disableCompanyHierarchy;

  const handleBulkActionAlert = dialogState => {
    setBulkActionWarningDialog(dialogState);
  };

  const callbackFromDialog = value =>
    hasMixedCompanyIds ? handleBulkActionAlert(true) : setBulkCancel(value);

  const { enqueueSnackbar } = useSnackbar();

  const { companyId } = useParams();

  const [checkedCompany, setCheckedCompany] = useState(companyId);

  const { t } = useTranslation('packages');

  const onChangeFilter = filters => {
    setFilter(filters, CLEAR_PAGINATION);
  };

  useEffect(() => {
    if (checked.length === 0) {
      setTrackingAndCompanyPair([]);
    }
  }, [checked]);

  useEffect(() => {
    if (bulkCancel) {
      packagesApi
        .bulkCancel(checkedCompany, checked)
        .then(response => setbulkCancelData(response))
        .catch(() => {
          enqueueSnackbar(t('list.bulkCancellation.toast'), {
            content: (key, message) => (
              <Toast id={key} message={message} type="error" />
            )
          });
        })
        .then(() => setBulkCancel(false));
    }
  }, [bulkCancel, checked, checkedCompany, enqueueSnackbar, t]);

  const addToCompanyAndTkList = itemTrackingKey => {
    const newChecked = [...trackingAndCompanyPair];
    newChecked.push({
      trackingKey: itemTrackingKey,
      companyId: packagesData?.find(pkg => pkg.trackingKey === itemTrackingKey)
        ?.companyId
    });
    setTrackingAndCompanyPair(newChecked);
  };

  const removeFromCompanyAndTkList = itemTrackingKey => {
    const indexToRemove = trackingAndCompanyPair.findIndex(
      pkg => pkg.trackingKey === itemTrackingKey
    );
    const newChecked = trackingAndCompanyPair;
    newChecked.splice(indexToRemove, 1);
    setTrackingAndCompanyPair(newChecked);
  };

  const getCheckedList = itemTrackingKey => {
    const currentIndex = checked.indexOf(itemTrackingKey);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      addToCompanyAndTkList(itemTrackingKey);
      newChecked.push(itemTrackingKey);
    } else {
      removeFromCompanyAndTkList(itemTrackingKey);
      newChecked.splice(currentIndex, 1);
    }

    return newChecked;
  };

  const handleCheckboxSelection = itemTrackingKey => {
    const checkedList = getCheckedList(itemTrackingKey);

    setChecked(checkedList);
  };

  const handleSelectAllCheckboxClick = () => {
    const shouldSelectAll =
      packagesData && packagesData.length !== checked.length;
    const shouldUnselectAll = !shouldSelectAll;
    if (shouldSelectAll) {
      const allPackagesTrackingKey = packagesData.map(pkg => pkg.trackingKey);
      const allPackagesTkAndCompany = packagesData.map(pkg => {
        return { trackingKey: pkg.trackingKey, companyId: pkg.companyId };
      });
      setTrackingAndCompanyPair(allPackagesTkAndCompany);
      setChecked(allPackagesTrackingKey);
    } else if (shouldUnselectAll) {
      setTrackingAndCompanyPair([]);
      setChecked([]);
    }
  };

  const handleToggle = itemTrackingKey => {
    const isSelectAllButtonClick = itemTrackingKey === undefined;
    if (isSelectAllButtonClick) {
      handleSelectAllCheckboxClick();
    } else {
      handleCheckboxSelection(itemTrackingKey);
    }
  };

  useEffect(() => {
    const getCompanyIdFrom = checkedTkList => {
      const getCompanyIds = () =>
        checkedTkList.map(
          trackingKey =>
            trackingAndCompanyPair?.find(pkg => pkg.trackingKey === trackingKey)
              ?.companyId
        );

      const getUniqueIdFromCompanyList = () => {
        return getCompanyIds().reduce((acc, curr) => {
          if (!acc.includes(curr)) {
            acc.push(curr);
          }
          return acc;
        }, []);
      };

      return getUniqueIdFromCompanyList();
    };
    setHasMixedCompanyIds(getCompanyIdFrom(checked).length > 1);
    setCheckedCompany(...getCompanyIdFrom(checked));
  }, [checked, trackingAndCompanyPair]);

  const goBackToPackagesList = () => {
    setBulkCancel(false);
    setbulkCancelData({});
  };

  const shouldDisplayOptions = !isLoading && itemsCount > 0;

  const allSelected = packagesData
    ? packagesData.length === checked.length
    : false;

  const indeterminateCheckboxState = !allSelected && checked.length !== 0;

  const CheckboxComponents = () => {
    return (
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box
          display="flex"
          alignItems="center"
          pl={{ xs: 1.5, sm: 3, md: 3.5 }}
          pt={{ xs: 1 }}
        >
          <Checkbox
            checked={allSelected}
            indeterminate={indeterminateCheckboxState}
            onClick={() => {
              handleToggle();
            }}
            data-testid="selectAllCheckbox"
          />
          <Box component="strong" fontWeight="fontWeightBold" pl={2}>
            {allSelected
              ? t('list.checkBox.removeAll')
              : t('list.checkBox.selectAll')}
          </Box>
        </Box>
        {checked.length > 0 && (
          <Box component="strong" fontWeight="fontWeightBold" pr={5}>
            {t('list.checkBox.totalSelected', { count: checked.length })}
          </Box>
        )}
      </Box>
    );
  };

  const PackagesListHeader = () => (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      paddingTop={4}
      paddingX={4}
    >
      <Box display="flex" pr={1}>
        <FilterIcon width={27} height={27} />
      </Box>
      <Typography variant="subtitle1">
        <Box fontWeight={600}>{t('list.filters.title')}</Box>
      </Typography>
    </Box>
  );

  return (
    <OneTemplate>
      <OneTemplateSummary>
        {hasBulkCancelData ? (
          <BulkCancellationResultsSummary
            goBackToPackagesList={goBackToPackagesList}
          />
        ) : (
          <PackagesSummary onChange={onChangeFilter} />
        )}
      </OneTemplateSummary>
      <OneTemplateContent disablePadding>
        {hasBulkCancelData ? (
          <BulkCancellationResults
            goBackToPackagesList={goBackToPackagesList}
            packagesData={packagesData}
            resultData={bulkCancelData}
          />
        ) : (
          <>
            <PackagesListHeader />
            <Box>
              <Box
                display="flex"
                flexDirection={{ xs: 'column', md: 'row' }}
                alignItems={{ md: 'center' }}
                justifyContent="space-between"
                pl={4}
                pb={2}
              >
                <PackagesSearchBar onChange={onChangeFilter} />
              </Box>
              <Box
                display="flex"
                flexDirection={{ xs: 'column', md: 'row' }}
                alignItems={{ md: 'center' }}
                justifyContent="space-between"
                pl={4}
                pb={2}
              >
                <PackagesFilters
                  setChecked={setChecked}
                  onChange={onChangeFilter}
                />
              </Box>
            </Box>
            {shouldDisplayOptions && (
              <Box
                width="stretch"
                justifyContent={{ xs: 'space-between' }}
                paddingX={4}
                paddingY={2}
                display="flex"
                alignItems={{ md: 'center' }}
                marginX={{ md: 4, xs: 2 }}
                mb={{ xs: 2 }}
                mt={{ xs: 2 }}
                height={{ md: 80, xs: 'fit-content' }}
                borderRadius={16}
                className={classes.boxOptions}
                flexDirection={{ xs: 'column', md: 'row' }}
              >
                <Box mb={{ xs: 2, md: 0 }}>
                  {checked.length > 0 ? (
                    <Typography variant="body1" align="center">
                      {t('list.checkBox.userSelection')}
                      <Box component="strong" fontWeight="fontWeightBold">
                        {t('list.checkBox.selectedByUser', {
                          count: checked.length
                        })}
                      </Box>
                    </Typography>
                  ) : (
                    <Typography variant="body1" align="center">
                      {t('list.filters.response.filterSelection')}
                      <Box
                        pl={0.5}
                        component="strong"
                        fontWeight="fontWeightBold"
                      >
                        {t('list.filters.response.middleTitle', {
                          count: itemsCount
                        })}
                      </Box>
                    </Typography>
                  )}
                </Box>
                <Box
                  display="flex"
                  flexDirection={{ xs: 'column', sm: 'row' }}
                  justifyContent={{ xs: 'flex-end' }}
                  marginX={{ xs: 1 }}
                  flex={1}
                >
                  {checked.length > 0 && (
                    <PackagesBulkDownloadLabelsButton
                      companyId={checkedCompany}
                      trackingKeysSelected={checked}
                      hasMixedCompanyIds={hasMixedCompanyIds}
                      handleBulkActionAlert={handleBulkActionAlert}
                    />
                  )}
                  {checked.length > 0 && (
                    <PackagesBulkCancellationButton
                      trackingKeysSelected={checked}
                      callback={callbackFromDialog}
                      hasMixedCompanyIds={hasMixedCompanyIds}
                      handleBulkActionAlert={handleBulkActionAlert}
                    />
                  )}
                  <PackagesReportButton />
                </Box>
              </Box>
            )}
            <PackagesListTabs setChecked={setChecked} onChange={onChangeFilter}>
              {showSelectAllCheckBox && <CheckboxComponents />}
              {showSelectAllCheckBox && <Divider />}
              <PackagesListWrapper
                count={itemsCount}
                data={packagesData}
                isLoading={isLoading}
                error={error}
                useHierarchySpecifcs={useHierarchySpecifcs}
                handleToggle={handleToggle}
                setChecked={setChecked}
                checked={checked}
              />
            </PackagesListTabs>
            {bulkCancel && (
              <LoadingDialog message={t('list.bulkCancellation.loading')} />
            )}
            <NotificationDialog
              title={t('list.bulkActionWarningDialog.title')}
              subtitle={t('list.bulkActionWarningDialog.subtitle')}
              closeButtonLabel={t('list.bulkActionWarningDialog.buttonLabel')}
              dialogState={bulkActionWarningDialog}
              setDialogState={setBulkActionWarningDialog}
            />
          </>
        )}
      </OneTemplateContent>
    </OneTemplate>
  );
};

export default PackagesList;
