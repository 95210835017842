import ROLES from './roles';

const {
  ADMIN,
  ADMIN_LARGE,
  FINANCE,
  MANAGER_LARGE,
  OPERATOR,
  OPERATOR_LARGE,
  REGULAR,
  OWNER,
  OWNER_LARGE
} = ROLES;

const accessItems = {
  addressesList: [
    ADMIN,
    OWNER,
    REGULAR,
    OPERATOR,
    OPERATOR_LARGE,
    ADMIN_LARGE,
    MANAGER_LARGE,
    OWNER_LARGE
  ],
  finance: [
    ADMIN,
    OWNER,
    REGULAR,
    FINANCE,
    ADMIN_LARGE,
    MANAGER_LARGE,
    OWNER_LARGE
  ],
  integratorList: [
    ADMIN,
    OWNER,
    REGULAR,
    ADMIN_LARGE,
    MANAGER_LARGE,
    OWNER_LARGE
  ],
  management: [
    ADMIN,
    ADMIN_LARGE,
    MANAGER_LARGE,
    OPERATOR,
    OPERATOR_LARGE,
    OWNER,
    OWNER_LARGE,
    REGULAR
  ],
  packageChargeDetail: [
    ADMIN,
    OWNER,
    REGULAR,
    OPERATOR,
    ADMIN_LARGE,
    MANAGER_LARGE,
    OWNER_LARGE
  ],
  packageInvoiceDetail: [
    ADMIN,
    OWNER,
    REGULAR,
    OPERATOR,
    ADMIN_LARGE,
    MANAGER_LARGE,
    OWNER_LARGE
  ],
  packages: [
    ADMIN,
    OWNER,
    REGULAR,
    OPERATOR,
    OPERATOR_LARGE,
    ADMIN_LARGE,
    MANAGER_LARGE,
    OWNER_LARGE
  ],
  recurringPickup: [ADMIN, OWNER, REGULAR, OPERATOR],
  tracking: [ADMIN, OWNER, REGULAR, OPERATOR],
  usersManagement: [ADMIN, OWNER, ADMIN_LARGE, OWNER_LARGE],
  companyData: [ADMIN, ADMIN_LARGE, OWNER, OWNER_LARGE]
};

export default accessItems;
