import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  OutlinedInput,
  Select,
  useTheme
} from '@material-ui/core';
import { useQueryParams } from '@loggi/components/src/one/hooks';
import { useTranslation } from 'react-i18next';

import {
  PACKAGES_DATE_FILTER_VALUES as DATE_VALUES,
  DATE_PERIOD,
  DEFAULT_SEARCH_DAYS,
  FILTERS_NAMES
} from '../../constants';
import DatePickerDialog from './filter-date/DatePickerDialog';
import DateFilterOption from './filter-date/DateFilterOption';

const FilterDate = ({ setChecked, onChange }) => {
  const { spacing } = useTheme();
  const queryParams = useQueryParams();
  const initialFilter = queryParams[FILTERS_NAMES.DATE];
  const startDateFilter = queryParams[FILTERS_NAMES.START_DATE];
  const finalDateFilter = queryParams[FILTERS_NAMES.FINAL_DATE];

  const isDatePeriodFilter = startDateFilter && finalDateFilter;
  const filter = initialFilter || DEFAULT_SEARCH_DAYS;
  const [openDialog, setOpenDatePickerDialog] = useState(false);
  const [created, setCreated] = useState(Number(filter));
  const { t } = useTranslation('packages');

  useEffect(() => {
    setCreated(Number(filter));
  }, [filter, setChecked]);

  const handleCloseDatePickerDialog = () => {
    setOpenDatePickerDialog(false);
  };

  const renderDateFilterLabel = value => {
    return isDatePeriodFilter
      ? `${startDateFilter.replace(/-/g, '/')} - ${finalDateFilter.replace(
          /-/g,
          '/'
        )}`
      : t('filters.date.lastDays', { days: `${value}`.padStart(2, '0') });
  };

  const MenuProps = {
    PaperProps: {
      style: {
        marginTop: spacing(1),
        maxWidth: spacing(30)
      }
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left'
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left'
    },
    getContentAnchorEl: null
  };

  const onChangeDate = event => {
    if (event.target.value !== DATE_PERIOD) {
      const value = Number(event.target.value);
      setCreated(value);
      const newFilter = Object();
      newFilter[FILTERS_NAMES.DATE] =
        value === DEFAULT_SEARCH_DAYS ? null : value;
      newFilter[FILTERS_NAMES.START_DATE] = null;
      newFilter[FILTERS_NAMES.FINAL_DATE] = null;
      onChange(newFilter);
      // cleans checked packages list
      setChecked([]);
    }
  };

  const FilterDateOptions = () => {
    return DATE_VALUES.map(value => (
      <MenuItem key={value} value={value} data-testid={value}>
        <FormControlLabel
          label={t('filters.date.lastDays', { days: value })}
          checked={created === value && !isDatePeriodFilter}
          control={<Radio color="primary" />}
        />
      </MenuItem>
    ));
  };

  return (
    <>
      <FormControl fullWidth variant="outlined">
        <InputLabel>{t('filters.date.text')}</InputLabel>
        <Select
          id="filter-date-select"
          input={
            <OutlinedInput
              data-testid="filter-date-select"
              label={t('filters.date.text')}
            />
          }
          MenuProps={MenuProps}
          labelId="filter-date-select-label"
          onChange={onChangeDate}
          renderValue={renderDateFilterLabel}
          value={isDatePeriodFilter ? DATE_PERIOD : created}
        >
          {FilterDateOptions()}
          <MenuItem value={DATE_PERIOD}>
            <DateFilterOption
              onClickDateFilter={() => {
                setOpenDatePickerDialog(true);
              }}
            />
          </MenuItem>
        </Select>
      </FormControl>
      {openDialog && (
        <DatePickerDialog
          handleCloseDatePickerDialog={handleCloseDatePickerDialog}
          onChangeDate={onChange}
        />
      )}
    </>
  );
};

FilterDate.propTypes = {
  setChecked: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default FilterDate;
