import wretch from 'wretch';
import { ACCESS_DENIED, COGNITO_IU, LOGIN } from './constants';
import oneAuthMiddleware from './one-auth-middleware';

// Wretch object is immutable which means that you can configure, store and reuse instances.
// This seems like a good place to put things that are common to all requests.
//
// More: https://github.com/elbywan/wretch#because-configuration-should-not-rhyme-with-repetition

/**
 * pickupsApi is a wretch instance for requests to Pickups RestAPI.
 *
 * @return {Object}
 */

const pickupsApi = wretch()
  .url(process.env.REACT_APP_LOGGI_WEB_URL)
  .middlewares([oneAuthMiddleware])
  .resolve(_ =>
    _.unauthorized(() => {
      localStorage.removeItem(COGNITO_IU);
      window.location.assign(LOGIN);
    }).forbidden(() => {
      window.location.assign(ACCESS_DENIED);
    })
  );

export default pickupsApi;
