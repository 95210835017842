import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useFeature } from '@loggi/components/src/one/remote-config';
import React from 'react';
import PropTypes from 'prop-types';
import { Company, useHierarchyChildren } from '@loggi/components/src/one/hooks';
import { useRemoteConfig } from '../../../../firebase';

import FilterDate from './filter-date';
import FilterStatus from './filter-status';
import FilterIntegrator from './filter-integrator';
import FilterHierarchy from './filter-hierarchy';

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  root: {
    paddingRight: spacing(3),
    [breakpoints.up('md')]: {
      paddingTop: spacing(2)
    }
  },
  filterDate: {
    paddingTop: spacing(2),
    [breakpoints.up('md')]: {
      paddingTop: spacing(0)
    },
    minWidth: spacing(35),
    [breakpoints.down('md')]: {
      width: '100%'
    }
  },
  filterStatus: {
    paddingTop: spacing(2),
    [breakpoints.up('md')]: {
      paddingTop: spacing(0),
      maxWidth: spacing(10)
    },
    minWidth: spacing(37),
    [breakpoints.down('md')]: {
      width: '100%'
    }
  },
  filterIntegrator: {
    paddingTop: spacing(2),
    [breakpoints.up('md')]: {
      paddingTop: spacing(0),
      maxWidth: spacing(10)
    },
    minWidth: spacing(35),
    [breakpoints.down('md')]: {
      width: '100%'
    }
  },
  filterHierarchy: {
    paddingTop: spacing(2),
    [breakpoints.up('md')]: {
      paddingTop: spacing(0),
      maxWidth: spacing(10)
    },
    minWidth: spacing(35),
    [breakpoints.down('md')]: {
      width: '100%'
    }
  }
}));

/**
 * PackagesFilters renders filters that modify the URL with parameters.
 * @componentType presentational
 * @returns {*}
 * @constructor
 */
const PackagesFilters = ({ setChecked, onChange }) => {
  const company = Company.getInstance();
  const companyIntegrators = company.getCompanyIntegrators() ?? {};

  const hierarchyChildrenInfo = useHierarchyChildren() ?? [];
  const hasChildren = () => hierarchyChildrenInfo.length !== 0;

  const enableIntegratorFilter = useFeature('enable_integrator_filter');
  const disableCompanyHierarchy = useFeature('disable_company_hierarchy');
  const { value: fsHierarchyFilter } = useRemoteConfig(
    'enable_company_cnpj_in_package_list'
  );

  const enableHierarchyFilter =
    fsHierarchyFilter === 'true' && !disableCompanyHierarchy;

  const styles = useStyles();
  return (
    <Box
      className={styles.root}
      display="flex"
      flexDirection={{ xs: 'column', md: 'row' }}
      justifyContent="space-between"
    >
      <Box className={styles.filterStatus} pr={{ md: 3 }}>
        <FilterStatus setChecked={setChecked} onChange={onChange} />
      </Box>
      <Box className={styles.filterDate} pr={{ md: 3 }}>
        <FilterDate setChecked={setChecked} onChange={onChange} />
      </Box>
      {enableIntegratorFilter &&
        (Object.keys(companyIntegrators).length !== 0 && (
          <Box className={styles.filterIntegrator} pr={{ md: 3 }}>
            <FilterIntegrator
              setChecked={setChecked}
              onChange={onChange}
              integratorsData={companyIntegrators}
            />
          </Box>
        ))}
      {enableHierarchyFilter &&
        (hasChildren() && (
          <Box className={styles.filterHierarchy}>
            <FilterHierarchy
              setChecked={setChecked}
              onChange={onChange}
              hierarchyChildrenList={hierarchyChildrenInfo}
            />
          </Box>
        ))}
    </Box>
  );
};

PackagesFilters.propTypes = {
  setChecked: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default PackagesFilters;
