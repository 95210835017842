import AuthenticationRoutes, { LOGIN_ROUTE } from '@loggi/authentication';
import { AmplifyConfigure, useAmplifyAuth } from '@loggi/authentication-lib';
import ImpersonationBanner from '@loggi/authentication/src/components/impersonation-banner';
import { CreateNewPassword } from '@loggi/authentication/src/screens';
import { GOOGLE_PROVIDER } from '@loggi/authentication/src/screens/signin/constants';
import { LOGIN_STORAGE_KEYS } from '@loggi/components/src/one/constants';
import {
  preloadFirebaseConfigs,
  useRemoteConfig
} from '@loggi/components/src/one/remote-config';
import { redirectToHelpCenter } from '@loggi/components/src/one/utils';
import { useCheckCompanyStatus } from '@loggi/components/src/one/hooks';
import {
  PickupReceiptRoutes,
  PICKUP_RECEIPT_ROUTE
} from '@loggi/pickup-receipt';
import {
  PickupOrderScheduleRoutes,
  PICKUP_ORDER_SCHEDULE_PACKAGES_ROUTE
} from '@loggi/pickup-order-schedule';
import {
  Box,
  CircularProgress,
  CssBaseline,
  makeStyles
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  matchPath
} from 'react-router-dom';
import {
  useCurrentCompany,
  useCurrentUser,
  useSinglePointOfAccess
} from './auth';
import config from './aws-exports';
import Notification from './components/notification';
import RemoteConfigToast from './components/remote-config-toast';
import { CompanyStatusAlert } from './components/company-status-notification';
import useAppcues from './configuration/appcues-init';
import applyGoogleTranslatorMonkeyPatch from './configuration/apply-google-translator.monkey-patch';
import {
  LOADING_ID,
  SIGN_UP_GOOGLE_USER_LINK_REDIRECT,
  SIGN_UP_USER_ALREADY_EXISTS_ERROR
} from './constants';
import AllowedRoutes from './routes';
import { CREATE_NEW_PASSWORD_ROUTE } from './routes/constants';
import { useSentry } from './sentry';
import SignupCompany from './signup-company/sign-up-company-wizard';
import EnviosHeader from './components/header/header';

AmplifyConfigure(config);

global.loggi = {
  version: process.env.REACT_APP_VERSION
};

const SIGN_UP_URL = `${
  process.env.REACT_APP_BEYOND_URL
}/cadastro/conta?redirect=envios`;

const useAppStyles = makeStyles(({ breakpoints }) => ({
  root: {
    height: '100%',
    paddingTop: 0
  },
  '@global': {
    [breakpoints.up('md')]: {
      html: {
        overflowX: 'hidden',
        width: '100vw'
      },
      body: {
        // This line disabled the overflow strategy applied by the material-ui
        // form fields in favor of the one already used above
        paddingRight: '0 !important'
      }
    }
  },
  loading: {
    position: 'absolute',
    top: '50%',
    left: '50%'
  }
}));

const App = () => {
  const {
    state: { isImpersonation, loading, newPasswordRequired, urlRedirect },
    getCurrentUserSession,
    federatedSignIn,
    setUrlRedirect
  } = useAmplifyAuth();

  const [firebaseLoading, setFirebaseLoading] = useState(true);
  const [redirecting, setRedirecting] = useState(false);
  const company = useCurrentCompany();
  const history = useHistory();
  const location = useLocation();
  const user = useCurrentUser();
  const {
    isDefaulter,
    isOutOfCoverage: isOutOfCoverageCompanyStatus
  } = useCheckCompanyStatus();

  useSentry();
  useAppcues();

  useEffect(() => {
    preloadFirebaseConfigs().then(() => {
      applyGoogleTranslatorMonkeyPatch();
      setFirebaseLoading(false);
    });
  }, []);

  const impersonationSession = isImpersonation;
  const styles = useAppStyles();
  const isFromSignUpCompany = location?.state?.isFromSignUpCompany;
  const search = location?.search;
  const enableZendeskLoginRedirect = useRemoteConfig(
    'enable_login_redirect_to_zendesk_help_center'
  );
  const isSinglePointOfAccessEnabled = useSinglePointOfAccess();

  const goToZendesk = useCallback(async () => {
    await redirectToHelpCenter(company.id, user, enableZendeskLoginRedirect);
  }, [company, user, enableZendeskLoginRedirect]);

  useEffect(() => {
    const params = new URLSearchParams(search || '');
    const redirectService = params.get(LOGIN_STORAGE_KEYS.REDIRECT_SERVICE);
    if (redirectService) {
      localStorage.setItem(
        LOGIN_STORAGE_KEYS.REDIRECT_SERVICE,
        redirectService
      );
    }
  }, [search]);

  useEffect(() => {
    const redirectService = localStorage.getItem(
      LOGIN_STORAGE_KEYS.REDIRECT_SERVICE
    );
    if (user && company && !loading && !redirecting) {
      const currentCompanyRole = user.companyAccess.find(
        ({ id }) => id === company.id
      )?.role;
      if (
        currentCompanyRole === 'SUPPORT' ||
        redirectService === LOGIN_STORAGE_KEYS.ZENDESK
      ) {
        goToZendesk();
      }
    }
  }, [company, goToZendesk, loading, redirecting, user]);

  useEffect(() => {
    if (isFromSignUpCompany) {
      getCurrentUserSession();
      const state = { ...location.state };
      delete state.isFromSignUpCompany;
      history.replace({ ...history.location, state });
    }
  }, [isFromSignUpCompany, getCurrentUserSession, history, location]);

  useEffect(() => {
    if (!search) {
      return;
    }
    if (search.includes(SIGN_UP_USER_ALREADY_EXISTS_ERROR)) {
      localStorage.clear();
      history.push(LOGIN_ROUTE, { invalidSignUp: true });
    } else if (search.includes(SIGN_UP_GOOGLE_USER_LINK_REDIRECT)) {
      setRedirecting(true);
      federatedSignIn(GOOGLE_PROVIDER);
    }
  }, [search, history, federatedSignIn]);

  if (loading || redirecting || firebaseLoading) {
    return (
      <Box className={styles.loading}>
        <CircularProgress data-testid={LOADING_ID} />
      </Box>
    );
  }

  const isPickupReceiptPublic =
    matchPath(location.pathname, {
      path: PICKUP_RECEIPT_ROUTE.path
    }) !== null;

  const isPickupOrderSchedulePackages =
    matchPath(location.pathname, {
      path: PICKUP_ORDER_SCHEDULE_PACKAGES_ROUTE.path
    }) !== null;

  const redirectCompanySignup =
    !company || (company && location?.state?.addNewCompany);

  return (
    <>
      <CssBaseline />
      <RemoteConfigToast />
      {impersonationSession && <ImpersonationBanner name={user?.name} />}
      {isDefaulter && <CompanyStatusAlert />}
      <Box className={styles.root}>
        <EnviosHeader
          isPickupReceiptPublic={isPickupReceiptPublic}
          isPickupOrderSchedulePackages={isPickupOrderSchedulePackages}
          isDefaulter={isDefaulter}
          isOutOfCoverageCompanyStatus={isOutOfCoverageCompanyStatus}
        />

        <Switch>
          {(isPickupOrderSchedulePackages && <PickupOrderScheduleRoutes />) ||
            (isPickupReceiptPublic && <PickupReceiptRoutes />) ||
            (!user && (
              <AuthenticationRoutes
                target="envios"
                signUpRedirect={
                  isSinglePointOfAccessEnabled ? SIGN_UP_URL : undefined
                }
              />
            )) ||
            (newPasswordRequired && (
              <>
                <Route
                  path={CREATE_NEW_PASSWORD_ROUTE}
                  component={CreateNewPassword}
                />
                <Redirect from="*" to={CREATE_NEW_PASSWORD_ROUTE} />
              </>
            )) ||
            (redirectCompanySignup && <SignupCompany />) || (
              <>
                <AllowedRoutes
                  company={company}
                  isImpersonation={impersonationSession}
                  urlRedirect={urlRedirect}
                  setUrlRedirect={setUrlRedirect}
                  isOutOfCoverageCompanyStatus={isOutOfCoverageCompanyStatus}
                />
                <Notification companyId={company.id} />
              </>
            )}
        </Switch>
      </Box>
    </>
  );
};

export default App;
